import {
    useLayoutEffect,
    useState,
    useCallback,
    RefObject,
    useRef,
} from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { useDataRef } from '@frontend/jetlend-core/src/hooks/useDataRef';

type SizeRect = {
    width: number;
    height: number;
}

/**
 * TODO
 * @category Hooks
 */
export const useResizeObserver = <TElement extends HTMLElement>(
    ref: RefObject<TElement>,
    callback?: (rect: SizeRect) => void
): number[] => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const callbackRef = useDataRef(callback);

    const sizeRef = useRef<SizeRect>({
        width: 0,
        height: 0,
    });

    const updateSize = useCallback((w: number, h: number) => {
        const roundedWidth = Math.round(w);
        const roundedHeight = Math.round(h);

        const size = sizeRef.current;

        if (roundedWidth !== size.width || roundedHeight !== size.height) {
            sizeRef.current = {
                width: roundedWidth,
                height: roundedHeight,
            };

            setWidth(roundedWidth);
            setHeight(roundedHeight);

            if (typeof callbackRef.current === 'function') {
                callbackRef.current({ ...sizeRef.current });
            }
        }
    }, [ callbackRef, sizeRef ]);

    useLayoutEffect(() => {
        if (!ref) {
            return;
        }

        let unmounted = false;

        if (ref.current) {
            const rects = ref.current.getClientRects();
            if (rects && rects.length > 0) {
                const rect = rects[0];
                if (rect) {
                    updateSize(rect.width, rect.height);
                }
            }
        }

        const observerInstance: ResizeObserver = new ResizeObserver(entries => {
            window.requestAnimationFrame(() => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }

                if (unmounted) {
                    return;
                }

                if (!Array.isArray(entries)) {
                    return;
                }

                const entry = entries[0];
                if (entry.target.parentElement) {
                    const rect = entry.contentRect;
                    updateSize(rect.width, rect.height);
                }
            });
        });

        if (ref.current) {
            observerInstance.observe(ref.current);
        }

        let observingElement: HTMLElement | null = ref.current;
        let observingParentElement: HTMLElement | null = null;

        const intervalId = setInterval(() => {
            if (observingElement !== ref.current) {
                // When observing element was changed we need to change subscription on ResizeObserver
                if (observingElement) {
                    observerInstance.unobserve(observingElement);
                }

                if (ref.current) {
                    observerInstance.observe(ref.current);

                    const rects = ref.current.getClientRects();
                    if (rects && rects.length > 0) {
                        const rect = rects[0];
                        if (rect) {
                            updateSize(rect.width, rect.height);
                        }
                    }
                }
            }

            observingElement = ref.current;

            if (ref.current && observingParentElement !== ref.current.parentElement) {
                const rects = ref.current.getClientRects();
                if (rects && rects.length > 0) {
                    const rect = rects[0];
                    if (rect) {
                        updateSize(rect.width, rect.height);
                    }
                }
            }

            observingParentElement = ref.current?.parentElement ?? null;
        }, 250);

        return () => {
            unmounted = true;
            observerInstance && observerInstance.disconnect();
            clearInterval(intervalId);
        };
    }, [ref, updateSize]);

    return [width, height];
};
