import React from 'react';
import NextImage from 'next/image';

const basePath = process.env.NEXT_PUBLIC_BASE_PATH;

const Image: React.FC<React.ComponentProps<typeof NextImage>> = props => {
    const src = ((typeof props.src === 'string') && (props.src.startsWith('/')))
        ? `${basePath || ''}${props.src}`
        : props.src;

    // eslint-disable-next-line jsx-a11y/alt-text
    return <NextImage {...props} src={src} />;
};

export default Image;
