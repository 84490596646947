import { combineReducers } from 'redux';
import { simpleFormHandler } from '@frontend/jetlend-core/src/ducks/form';
import {
    all,
    select,
    put,
    take,
    call,
} from 'redux-saga/effects';
import {
    requirePhone,
    required,
} from '@frontend/jetlend-core/src/validators';
import { simpleChartApiHandler } from '@frontend/jetlend-core/src/ducks/charts';
import {
    IApiHandler,
    simpleApiHandler,
} from '@frontend/jetlend-core/src/ducks/api';
import { parseDate } from '@frontend/jetlend-core/src/formatters/formatUtils';
import moment from 'moment';
import { objectHandler } from '@frontend/jetlend-core/src/ducks/object';
import {
    IInvestmentCalculatorResult,
    IInvestorsJetLendStatisticsApiModel,
    IInvestorsNplChartPointApiModel,
    IInvestorsReliabilityPointApiModel,
    InvestorsExternalIndicesChartIndexType,
} from '../../models/investors/investors';
import {
    apiGetInvestorsExternalIndicesChart,
    apiGetInvestorsJetLendPublicIndicesChart,
    apiGetInvestorsJetLendStatistics,
    apiGetInvestorsNplChart,
    apiGetInvestorsReliabilityChart,
} from '../../services/client/investors/investorsService';
import { startRegistration } from '@app/ducks/common/registration';
import { ClientType } from '@app/models/common/common';
import { sendEvent } from '../common/analytics';

export const PREFIX = 'investors';

// TODO This is draft, need to rework

type PhoneFormModel = {
    phone: string;
}

export const investorsPhoneFormHandler = simpleFormHandler<PhoneFormModel>(
    PREFIX, 'phone', {
        v2: {
            phone: [ required(), requirePhone() ],
        },
    }, {
        *onBeforeSubmit (values) {
            yield put(sendEvent('investor-button'));

            return values;
        },
        apiMethod: () => ({ status: 'OK' }),
        *onSuccess(_, values) {
            yield put(startRegistration(ClientType.Investor, values.phone));
        },
    }, {}
);

export const investorsNplChartHandler = simpleChartApiHandler<IInvestorsNplChartPointApiModel, any>(
    PREFIX, 'npl_chart', size => apiGetInvestorsNplChart(size)
);

export const investorsReliabilityChartHandler = simpleChartApiHandler<IInvestorsReliabilityPointApiModel, any>(
    PREFIX, 'reliability_chart', apiGetInvestorsReliabilityChart
);

export const investorsJetLendStatisticsHandler = simpleApiHandler<IInvestorsJetLendStatisticsApiModel>(
    PREFIX, 'jetlend_statistics', apiGetInvestorsJetLendStatistics
);

export const investorsJetLendIndicesChartHandler = simpleChartApiHandler(
    PREFIX, 'jetlend_indices_chart', size => apiGetInvestorsJetLendPublicIndicesChart(size)
);

function* waitForFetchDone(handler: IApiHandler<any>) {
    const isFetching = yield select(handler.isFetching as any);
    if (isFetching) {
        const event = yield take(handler.FETCH_DONE);
        // Return event back to the queue to continue event handling by other handlers
        yield put(event);
    }
}

function* syncedWithJetLendChart(size: number, index_name: InvestorsExternalIndicesChartIndexType) {
    yield waitForFetchDone(investorsJetLendIndicesChartHandler);

    const jlSize = yield select(investorsJetLendIndicesChartHandler.size as any);

    if (size !== jlSize) {
        yield put(investorsJetLendIndicesChartHandler.updateOptions(size));

        yield waitForFetchDone(investorsJetLendIndicesChartHandler);
    }

    const jlData = yield select(investorsJetLendIndicesChartHandler.selector as any);

    if (jlData && jlData.length > 0) {
        const firstDate = parseDate(jlData[0].date);

        const delta = firstDate?.diff(moment(), 'day') || 1;

        return yield call(apiGetInvestorsExternalIndicesChart, -delta, index_name);
    }

    return yield call(apiGetInvestorsExternalIndicesChart, size, index_name);
}

export const investorsIMOEXIndicesChartHandler = simpleChartApiHandler(
    PREFIX, 'imoex_indices_chart', function* (size = 0) {
        return yield syncedWithJetLendChart(size, InvestorsExternalIndicesChartIndexType.IMOEX);
    }
);

export const investorsCBONDIndicesChartHandler = simpleChartApiHandler(
    PREFIX, 'chart_indices_cbond', function* (size = 0) {
        return yield syncedWithJetLendChart(size, InvestorsExternalIndicesChartIndexType.CBOND);
    }
);

export const investorsDepositIndicesChartHandler = simpleChartApiHandler(
    PREFIX, 'chart_indices_deposit', function* (size = 0) {
        return yield syncedWithJetLendChart(size, InvestorsExternalIndicesChartIndexType.DEPOSIT);
    }
);

export const investorsRealEstateIndicesChartHandler = simpleChartApiHandler(
    PREFIX, 'chart_indices_real_estate', function* (size = 0) {
        return yield syncedWithJetLendChart(size, InvestorsExternalIndicesChartIndexType.REAL_ESTATE);
    }
);

export const investmentCalculatorHandler = objectHandler<{result: IInvestmentCalculatorResult|null}>(PREFIX, 'calculator', () => ({ result: null }));

export const reducer = combineReducers({
    ...investorsPhoneFormHandler.reducerInfo,
    ...investorsNplChartHandler.reducerInfo,
    ...investorsReliabilityChartHandler.reducerInfo,
    ...investorsJetLendStatisticsHandler.reducerInfo,
    ...investorsJetLendIndicesChartHandler.reducerInfo,
    ...investorsIMOEXIndicesChartHandler.reducerInfo,
    ...investorsCBONDIndicesChartHandler.reducerInfo,
    ...investorsDepositIndicesChartHandler.reducerInfo,
    ...investorsRealEstateIndicesChartHandler.reducerInfo,
    ...investmentCalculatorHandler.reducerInfo,
});

export function* rootSaga() {
    yield all([
        ...investorsPhoneFormHandler.effects,
        ...investorsNplChartHandler.effects,
        ...investorsReliabilityChartHandler.effects,
        ...investorsJetLendStatisticsHandler.effects,
        ...investorsJetLendIndicesChartHandler.effects,
        ...investorsIMOEXIndicesChartHandler.effects,
        ...investorsCBONDIndicesChartHandler.effects,
        ...investorsDepositIndicesChartHandler.effects,
        ...investorsRealEstateIndicesChartHandler.effects,
    ]);
}
