import {
    IRatingInfo,
    AutoInvestStrategyType,
} from '../autoinvest';
import {
    BaseSectionWithBlocks,
    IBaseSectionApiModel,
} from '@app/models/common/common';

export enum InvestorsSectionType {
    FEATURES = 'features',
    CALCULATOR = 'calculator',
    HOW_IT_WORKS = 'how_it_works',
    GEOGRAPHY = 'geography',
    CHART = 'chart',
    CONDITIONS = 'conditions',
    FAQ = 'faq',
    MARKUP = 'markup',
}

export interface IInvestorsSectionBlockApiModel {
    title: string;
    value?: string;
    description?: string;
    image?: string;
}

export type InvestorsFeaturesSectionApiModel = BaseSectionWithBlocks<IInvestorsFeatureBlockApiModel>

export interface IInvestorsFeatureBlockApiModel extends IInvestorsSectionBlockApiModel {
    id: IInvestorsFeatureType;
}

export enum IInvestorsFeatureType {
    PROFITABILITY = 'profitability',
    RELIABILITY = 'reliability',
    LIQUIDITY = 'liquidity',
}

export type InvestorsStatisticsSectionApiModel = BaseSectionWithBlocks<IInvestorsSectionBlockApiModel>

export interface IInvestorsGeographySectionApiModel extends IBaseSectionApiModel {
    image: string;
    regions: IInvestorsGeographyRegionApiModel[];
}

export interface IInvestorsGeographyRegionApiModel {
    title: string;
    part: string;
}

export interface IInvestorsChartSectionApiModel extends IBaseSectionApiModel {
    id: InvestorsChartType;
    header_description?: string;
    description?: string;
    events: IInvestorsChartEventApiModel[];
}

export enum InvestorsChartType {
    NPL_15 = 'npl15',
}

export interface IInvestorsChartEventApiModel {
    title: string;
    date: string;
}

export interface IConditionItemApiModel {
    title: string;
    description: string;
}

export interface IConditionsSectionApiModel extends IBaseSectionApiModel {
    items: IConditionItemApiModel[];
}

export interface IInvestmentCalculatorFormValues {
    ratingsInfo: IRatingInfo[];
    initialAmount: number;
    monthlyTopUpAmount: number;
    termInYears: number;
    strategy: AutoInvestStrategyType;
}

export interface IInvestmentChartPoint {
    month: number;
    capital: number;
    minCapital: number;
    maxCapital: number;
    /**
     * Минимальный доход инвестора
     */
    minIncome: number;
     /**
     * Максимальный доход инвестора
     */
    maxIncome: number;
    profitRate: number;
    minProfitRate: number;
    maxProfitRate: number;
}

export interface IInvestmentCalculatorResult {
    minExpectedAmount: number;
    maxExpectedAmount: number;
    minExpectedYieldRate: number;
    maxExpectedYieldRate: number;
     /**
     * Минимальный доход инвестора
     */
    minIncome: number;
     /**
     * Максимальный доход инвестора
     */
    maxIncome: number;
     /**
     * Срок инвестиций
     */
    termInYears?: number;

    jetlendChart: IInvestmentChartPoint[];
    stocksChart: IInvestmentChartPoint[];
    bondsChart: IInvestmentChartPoint[];
    depositsChart: IInvestmentChartPoint[];
    realEstatesChart: IInvestmentChartPoint[];
}

export interface IInvestorsNplChartPointApiModel  {
    date: string;
    npl_1: number;
    npl_15: number;
    default: number;
}

export interface IInvestorsReliabilityPointApiModel {
    min_interest_rate: number;
    max_interest_rate: number;
    investors_part: number;
    investors_count: number;
}

export interface IInvestorsJetLendStatisticsApiModel {
    min_interest_rate: number;
    max_interest_rate: number;

    /**
     * Средняя скорость сбора займа за последние 30 дней
     */
    average_duration: number;
    /**
     * Средневзвешенная ставка за последние 12 месяцев
     */
    average_interest_rate: number;
    /**
     * Средневзвешенная ставка за последние 30 дней
     */
    average_interest_rate_30days: number;
    /**
     * Доля дефолтов за последние 12 месяцев
     */
    default_rate: number;
    /**
     * Доля дефолтов за все время
     */
    default_rate_all: number;
    /**
     * Кол-во инвесторов
     */
    investors_count: number;
    /**
     * Сумма выданных займов
     */
    loans_amount: number;
    /**
     * Кол-во заемщиков
     */
    success_borrowers_count: number;
    /**
     * Кол-во активных инвесторов
     */
    active_investors_count: number;
    /**
     * Кол-во зарегистрированных заемщиков
     */
    borrowers_count: number;
    /**
     * Средневзвешенная ставка на вторичном рынке
     */
    average_market_interest_rate: number;
    /**
     * объем сделок на вторичном рынке за последние 30 дней
     */
    market_volume_30days: number;
    /**
     * Средне-взвешенная ставка по займам на сборке
     */
    waitings_interest_rate: number;

    inflation_rate: number;
    /**
     * Процент инвесторов, имеющие годовую доходность более уровня инфляции
     */
    investors_better_then_inflation: number;

    market_liquidity_speed: IInvestorsMarketLiquiditySpeed[];
    /**
     * Цена для расчета ликвидности
     */
    market_liquidity_price: number;
    /**
     * Дефолтность предыдущего поколения
     */
    last_period_default_rate: number;
    /**
     * Год предыдущего поколения
     */
    last_period_default: number;
    /**
     * Процент инвесторов, имеющие годовую доходность более уровня депозита
     */
    deposit_rate: number;
    /**
     * Средняя доходность за последние 3 месяца
     */
    jl_on_strategy_rate_90_days: number;
    /**
     * Информация о размере стандартного welcome бонуса
     */
    welcome_cashback_rate: number;

}

export interface IInvestorsMarketLiquiditySpeed {
    volume: 1000000 | 10000000;
    /**
     * Минуты
     */
    time: number;
}

export enum InvestorsExternalIndicesChartIndexType {
    IMOEX = 'IMOEX',
    CBOND = 'CBOND',
    DEPOSIT = 'DEPOSIT',
    REAL_ESTATE = 'real_estate',
}